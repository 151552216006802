import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToastMessage } from '@/redux/actions/toastMessageActions';
import fetchApi from '@/src/Middleware/fetchApi';
import { decrypt } from '@/src/Middleware/helpers';
import { getCookies, setCookie } from '@/src/Utils/Cookies';
import { useRouter } from 'next/router';

type response = {
  status?: boolean;
  [key: string]: any;
};

function Login() {
  const [currentEmail, setCurrentEmail] = useState('');
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const { dkt_em: encryptedEmail } = getCookies();
    if (encryptedEmail) {
      const decryptedEmail = decrypt(encryptedEmail);
      setCurrentEmail(decryptedEmail);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
  };
  const onClickLogin = async () => {
    try {
      const response: response = await fetchApi(null, '/api/login', {
        data: {
          email: currentEmail,
        },
      });

      if (response?.status) {
        dispatch(
          //@ts-ignore
          showToastMessage({
            appearance: 'success',
            type: 'toast',
            message: 'Welcome to Decathlon India!',
            timeout: 3000,
          }),
        );
        const redirectURL: any = router.query.redirect || process.env.NEXT_PUBLIC_SITE_URL;

        window.location.href = `${redirectURL}${redirectURL.includes('?') ? '&' : '?'}isLoggedIn=true`;
      } else {
        dispatch(
          // @ts-ignore
          showToastMessage({
            appearance: 'error',
            type: 'toast',
            message: response?.message || process.env.NEXT_PUBLIC_ERROR_MESSAGE,
            timeout: 3000,
          }),
        );
      }
    } catch (error) {
      dispatch(
        // @ts-ignore
        showToastMessage({
          appearance: 'error',
          type: 'toast',
          message: process.env.NEXT_PUBLIC_ERROR_MESSAGE,
          timeout: 3000,
        }),
      );
    }
  };

  const onClickHome = () => {
    setCookie('dkt_loadtest', 'true', { path: '/' });
    window.location.href = String(process.env.NEXT_PUBLIC_SITE_URL);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-md h-96 p-5 border-2 border-blue-50 rounded">
        <h2 className="text-center font-bold text-28 mb-4">Login Page</h2>
        <h2 className="font-semibold text-20 mb-4">Go to your Decathlon account here.</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email" className="block mb-2 text-20">
            Email address
          </label>
          <input
            type="email"
            id="email"
            value={currentEmail}
            onChange={(e) => setCurrentEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="w-full border border-gray-300 rounded-md py-2 px-4 mb-4"
          />
          <button
            onClick={(e) => onClickLogin()}
            type="submit"
            className="w-full p-3 bg-blue-400 text-white tracking-widest text-sm uppercase rounded"
          >
            Log In
          </button>
        </form>
        <button
          onClick={onClickHome}
          className="w-full p-3 bg-green-400 text-white tracking-widest text-sm uppercase rounded mt-4"
        >
          Home
        </button>
      </div>
    </div>
  );
}

Login.getInitialProps = async (ctx: any) => {
  const { res } = ctx;

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION') {
    res.writeHead(301, { Location: process.env.NEXT_PUBLIC_SITE_URL });
    res.end();
  }

  return {};
};

export default Login;
